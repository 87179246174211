import React from 'react'

export const NotFound = (props) => {
	return (
		<div className="NotFound">
			<div className="nav">¯\_(ツ)_/¯</div>
			<div className="body">
				<h1>NOT FOUND</h1>
			</div>
		</div>
	)
}

export default NotFound
