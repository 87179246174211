import React from 'react'
import { Router, useHistory } from 'react-router-dom'

import axios from 'axios'
import { Context as AuthContext } from 'context/AuthContext'
import { Context as I18nContext } from 'context/I18nContext'
import config from '../config'
import imgPlaceholder from '../assets/images/missing2.png'
import logoImg from '../assets/images/logo.png'
import SwipeableViews from 'react-swipeable-views'

import enImg from '../assets/images/ico_lingua_eng_1.png'
import itImg from '../assets/images/ico_lingua_i-ta_1.png'

const languageIcons = []
languageIcons['it'] = enImg
languageIcons['en'] = itImg

export const Home = (props) => {
	const auth = React.useContext(AuthContext)
	const i18n = React.useContext(I18nContext)
	const history = useHistory()

	const [scroll, setScroll] = React.useState(0)
	const [updateHeight, setUpdateHeight] = React.useState(false)
	const [pages, setPages] = React.useState([])

	var [currentPage, setCurrentPage] = React.useState(0)

	const loadData = async (reset) => {
		if (reset) setPages([])
		try {
			const { data: pages } = await axios({
				url: `${config.apiURL}/api/v1/pages/all`,
				method: 'POST',
				data: {
					version: config.version,
					client: config.client,
					accessToken: auth.accessToken,
				},
			})

			setPages(pages)
		} catch (error) {
			if (config.debug) console.log(error)
		}
	}

	React.useEffect(() => {
		// props.history.trigger('test')
		loadData()
	}, [])

	React.useEffect(() => {
		if (pages[currentPage]) {
			const test = props?.history?.ReactGA?.pageview(`/home/${pages[currentPage]?.name || ''}`)
		}
	}, [pages, currentPage])

	return (
		<div
			className={`home screen ${scroll > 0 ? 'scrolled' : ''}`}
			onScroll={(e) => {
				setScroll(e.target.scrollTop)
			}}
		>
			<div className="topbar disappearOnScroll"></div>
			<img src={logoImg} alt="LEFIOLE" className="logo disappearOnScroll" />
			<SwipeableViews
				className="body"
				animateHeight={updateHeight}
				enableMouseEvents
				index={currentPage}
				onChangeIndex={(index) => {
					console.log('tab changed', props.history)
					setCurrentPage(index)
				}}
			>
				{pages
					.filter((page) => page.enabled)
					.map((page, i) => (
						<div key={i} className="page" id="page">
							{page.posts
								.filter((post) => post.enabled)
								.map((post, i) => (
									<div
										key={i}
										className={`post ${post.open || post.blocks.length == 0 ? 'open' : 'closed'}`}
										onClick={(e) => {
											if (!post.open && post.blocks.length) {
												props.history.push(`/post/${post.id}`)
											}
										}}
									>
										<div className="post_head">
											<img
												className="post_picture"
												src={post.picture}
												alt={i18n.lang === 'it' ? post.name || post.name_en : post.name_en || post.name}
												onError={(e) => {
													e.target.onError = null
													e.target.src = imgPlaceholder
												}}
											></img>
											<div className="title">{i18n.lang === 'it' ? post.name || post.name_en : post.name_en || post.name}</div>
											{post.link && (
												<a
													className="link"
													target="_blank"
													href={i18n.lang === 'it' ? post.link_url || post.link_url_en : post.link_url_en || post.link_url}
													style={{ backgroundColor: post.link_color_bg, color: post.link_color_fg }}
												>
													{i18n.lang === 'it' ? post.link_name || post.link_name_en : post.link_name_en || post.link_name}
												</a>
											)}
										</div>
										{post.open &&
											post.blocks
												.filter((block) => block.enabled)
												.map((block, i) => (
													<div className="block" key={i}>
														{block.picture && !(block.content || block.content_en) && (
															<img
																className="block_picture"
																src={block.picture}
																alt={i18n.lang === 'it' ? block.name || block.name_en : block.name_en || block.name}
																onError={(e) => {
																	e.target.onError = null
																	e.target.src = imgPlaceholder
																}}
															/>
														)}
														{(block.content || block.content_en) && (
															<div
																className="content"
																style={
																	block.picture
																		? {
																				padding: 0,
																				backgroundImage: block.picture,
																		  }
																		: null
																}
																dangerouslySetInnerHTML={{
																	__html: i18n.lang === 'it' ? block.content || block.content_en : block.content_en || block.content,
																}}
															/>
														)}
													</div>
												))}
									</div>
								))}
						</div>
					))}
			</SwipeableViews>
			<div className="menu">
				{pages.map((page, i) => (
					<img
						key={i}
						src={page.picture}
						alt=""
						className={`icon ${currentPage === i ? 'on' : 'off'}`}
						onClick={(e) => {
							setUpdateHeight(true)
							setCurrentPage(i)
						}}
					/>
				))}
				<img
					src={languageIcons[i18n.lang]}
					alt=""
					className={`icon on`}
					onClick={(e) => {
						i18n.setLang(i18n.lang === 'it' ? 'en' : 'it')
					}}
				/>
			</div>
			<div className="footer">© 2021 LEFIOLE. ALL RIGHTS RESERVED</div>
		</div>
	)
}

export default Home
