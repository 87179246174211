import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { RouteWithLayout } from './components'
import { Main as MainLayout } from 'layouts'

import { Home } from 'views/Home.js'
import { Post } from 'views/Post.js'
import { NotFound } from 'views/NotFound/NotFound.js'

const Routes = (props) => {
	return (
		<Switch>
			<Route component={Home} exact path="/home" />
			<Route component={Post} exact path="/post/:post_id" />

			<RouteWithLayout component={NotFound} exact layout={MainLayout} path="/not-found" />

			<Redirect exact from="/" to="/home" />

			<Redirect to="/not-found" />
		</Switch>
	)
}

export default Routes
