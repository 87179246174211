import React from 'react'

import Logo from 'assets/images/logo.png'

const Topbar = (props) => {
	return (
		<div className="topbar">
			<a href="/" title="Lefiole - backoffice">
				<img alt="Lefiole - backoffice" src={Logo} />
			</a>
		</div>
	)
}

export default Topbar
