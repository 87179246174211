import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import config from 'config'

export const Context = React.createContext()
export const Consumer = Context.Consumer

export const Provider = (props) => {
	const [lang, setLang] = React.useState(localStorage.lang || (navigator.language || navigator.userLanguage).slice(0, 2).toLowerCase())

	React.useEffect(() => {
		if (lang != 'it' && lang != 'en') {
			setLang('en')
			return
		}
		localStorage.lang = lang
	}, [lang])

	return (
		<Context.Provider
			value={{
				lang,
				setLang,
			}}
		>
			{props.children}
		</Context.Provider>
	)
}

Provider.propTypes = {
	children: PropTypes.node.isRequired,
}
