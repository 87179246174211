import React from 'react'
import { useHistory } from 'react-router-dom'

import axios from 'axios'
import { Context as AuthContext } from 'context/AuthContext'
import { Context as I18nContext } from 'context/I18nContext'
import config from '../config'
import imgPlaceholder from '../assets/images/missing2.png'
import logoImg from '../assets/images/logo.png'
import backImg from '../assets/images/back.png'

export const Post = (props) => {
	const auth = React.useContext(AuthContext)
	const i18n = React.useContext(I18nContext)
	const history = useHistory()

	const [scroll, setScroll] = React.useState(0)
	const [page, setPage] = React.useState({})
	const [post, setPost] = React.useState({})

	const loadData = async (reset) => {
		try {
			const post_id = props.match.params.post_id

			const { data: post } = await axios({
				url: `${config.apiURL}/api/v1/posts/get`,
				method: 'POST',
				data: {
					version: config.version,
					client: config.client,
					accessToken: auth.accessToken,
					id: post_id,
				},
			})

			const { data: page } = await axios({
				url: `${config.apiURL}/api/v1/pages/get`,
				method: 'POST',
				data: {
					version: config.version,
					client: config.client,
					accessToken: auth.accessToken,
					id: post.page_id,
				},
			})

			setPage(page)
			setPost(post)
		} catch (error) {
			if (config.debug) console.log(error)
		}
	}

	React.useEffect(() => {
		if (!props.match.params.post_id) {
			props.history.goBack()
			return
		} else {
			loadData()
		}
	}, [])

	React.useEffect(() => {
		if (post?.name) {
			const test = props?.history?.ReactGA?.pageview(`/post/${post.name || ''}`)
		}
	}, [post])

	return (
		<div
			className={`post screen ${scroll > 0 ? 'scrolled' : ''}`}
			onScroll={(e) => {
				setScroll(e.target.scrollTop)
			}}
		>
			<div className="topbar disappearOnScroll"></div>
			<img
				src={backImg}
				className="back disappearOnScroll"
				onClick={(e) => {
					props.history.goBack()
				}}
			/>
			<img src={logoImg} alt="LEFIOLE" className="logo disappearOnScroll" />

			<div className="body">
				<div className="page">
					<div className="post">
						<div className="post_head">
							<img className="post_picture" src={post.picture} alt={i18n.lang === 'it' ? post.name || post.name_en : post.name_en || post.name}></img>
							<div className="title">{i18n.lang === 'it' ? post.name || post.name_en : post.name_en || post.name}</div>
							{post.link && (
								<a
									className="link"
									target="_blank"
									href={i18n.lang === 'it' ? post.link_url || post.link_url_en : post.link_url_en || post.link_url}
									style={{ backgroundColor: post.link_color_bg, color: post.link_color_fg }}
								>
									{i18n.lang === 'it' ? post.link_name || post.link_name_en : post.link_name_en || post.link_name}
								</a>
							)}
						</div>
						{post.blocks &&
							post.blocks
								.filter((block) => block.enabled)
								.map((block, i) => (
									<div className="block" key={i}>
										{!block.content && (
											<img
												className="block_picture"
												src={block.picture}
												alt={i18n.lang === 'it' ? block.name || block.name_en : block.name_en || block.name}
												onError={(e) => {
													e.target.onError = null
													e.target.src = imgPlaceholder
												}}
											/>
										)}
										{(block.content || block.content_en) && (
											<div
												className="content"
												style={{
													backgroundImage: (block.content || block.content_en) && block.picture ? `url("${block.picture}")` : null,
													padding: (block.content || block.content_en) && block.picture ? 0 : '20px',
												}}
												dangerouslySetInnerHTML={{
													__html: i18n.lang === 'it' ? block.content || block.content_en : block.content_en || block.content,
												}}
											/>
										)}
									</div>
								))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Post
