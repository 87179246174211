import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import config from 'config'

export const Context = React.createContext()
export const Consumer = Context.Consumer

export const Provider = (props) => {
	const [accessToken, setAccessToken] = React.useState(localStorage.accessToken)
	const [currentUser, setCurrentUser] = React.useState(localStorage.currentUser && JSON.parse(localStorage.currentUser))

	const login = (username, password) => {
		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: `${config.apiURL}/api/v1/auth/login`,
				data: {
					version: config.version,
					client: config.client,
					username,
					password,
				},
			})
				.then((res) => {
					if (res.data.user && res.data.token) {
						localStorage.accessToken = res.data.token
						localStorage.currentUser = JSON.stringify(res.data.user)

						setCurrentUser(res.data.user)
						setAccessToken(res.data.token)

						resolve()
					} else {
						if (config.debug) console.log(res.data.error)
						reject()
					}
				})
				.catch((error) => {
					if (config.debug) console.log(error)
					reject(error)
				})
		})
	}

	const logout = () => {
		localStorage.removeItem('accessToken')
		localStorage.removeItem('currentUser')

		setAccessToken(null)
		setCurrentUser(null)
	}

	const refresh = (relaod_image, callback) => {
		axios({
			method: 'post',
			url: `${config.apiURL}/api/v1/p/users/get`,
			data: {
				version: config.version,
				client: config.client,
				accessToken: accessToken,
			},
		})
			.then((res) => {
				localStorage.currentUser = JSON.stringify(res.data)

				if (relaod_image) {
					setCurrentUser({
						...res.data,
						picture: '',
					})
				}

				setCurrentUser(res.data)

				if (callback) callback(res.data || null)
			})
			.catch((error) => {
				if (config.debug) console.log(error)
				if (callback) callback(null)

				logout()
			})
	}

	const _refresh = React.useCallback(refresh)

	React.useEffect(() => {
		if (accessToken) _refresh()
	}, [accessToken])

	return (
		<Context.Provider
			value={{
				accessToken,
				currentUser,
				login,
				logout,
				refresh,
			}}
		>
			{props.children}
		</Context.Provider>
	)
}

Provider.propTypes = {
	children: PropTypes.node.isRequired,
}
