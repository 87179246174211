import React from 'react'
import PropTypes from 'prop-types'

import { Topbar, Footer } from './components'

const Main = (props) => {
	return (
		<div className="MainLayout">
			<Topbar />

			<main className="content">{props.children}</main>

			{/* <Footer /> */}
		</div>
	)
}

Main.propTypes = {
	children: PropTypes.node,
}

export default Main
