import React from 'react'

import * as auth from './AuthContext'
import * as i18n from './I18nContext'

export const Consumers = {
	auth: auth.Consumer,
	i18n: i18n.Consumer,
}

export const Contexts = {
	auth: auth.Context,
	i18n: i18n.Context,
}

export const Providers = {
	auth: auth.Provider,
	i18n: i18n.Provider,
}

export const ContextProvider = (props) => {
	return (
		<i18n.Provider>
			<auth.Provider>{props.children}</auth.Provider>
		</i18n.Provider>
	)
}
