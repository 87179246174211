export default {
	name: 'lefiole',
	apiURL: 'https://front.lefiole.vscloud.it',
	debug: false,
	version: 0.01,

	google: {
		analytics_monitoring_id: 'UA-120827599-2',
		tag_manager_id: 'GTM-5LMT4Z5D',
	},
	tinyMCE: {
		apiKey: 'kb8cn7f8h0ra51uz94mzp413bn5vle0rv3o7t6sensr5h9hk',
	},
}
